import {clsx} from 'clsx'

import {useState} from 'react'
import {Cart} from './cart'
import {Footer} from './footer'
import {Header} from './header'

import {Nav} from './nav'

export function Layout({data, children}: {data: any; children: React.ReactElement | null}) {
  const {footer, menu, cart} = data
  const [showNav, setShowNav] = useState(false)
  const [showCart, setShowCart] = useState(false)

  return (
    <>
      <Header
        onNavButtonClick={() => setShowNav(!showNav)}
        onCartButtonClick={() => setShowCart(true)}
      />
      {menu && <Nav data={menu} showNav={showNav} onNavButtonClick={() => setShowNav(!showNav)} />}
      <Cart showCart={showCart} setShowCart={setShowCart} data={cart} />

      {children}
      {footer && <Footer data={footer} />}

      <button
        type="button"
        onClick={() => {
          showNav ? setShowNav(false) : setShowCart(false)
        }}
        className={clsx(
          'ease-wakey fixed inset-0 z-30 bg-black/40 duration-300',
          showNav || showCart ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
        )}
      />
    </>
  )
}
