export const columns = 24
export const maxWidth = 1700

export function generateGrid() {
  if (process.env.NODE_ENV === 'development') {
    const offset = 0
    const offsetUnit = 'px'
    const lineThickness = '1px'
    const gutter = '0px'
    const color = 'rgba(255,0,0,0.07)'

    const repeatingWidth = `calc(100% / ${columns})`
    const columnWidth = `calc((100% / ${columns}) - ${gutter})`
    const backgroundWidth = `calc(100% + ${gutter})`
    const backgroundColumns = `repeating-linear-gradient(
      to right,
      ${color},
      ${color} ${lineThickness},
      transparent ${lineThickness},
      transparent calc(${columnWidth} - ${lineThickness}),
      ${color} calc(${columnWidth} - ${lineThickness}),
      ${color} ${columnWidth},
      transparent ${columnWidth},
      transparent ${repeatingWidth}
    )`

    return `
      body::before {
        max-width: ${maxWidth}px;
        margin: auto;
        content: "";
        position: fixed;
        display: block;
        z-index: 10000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        width: calc(100% - ${2 * offset}${offsetUnit});
        min-height: 100vh;
        background-image: ${backgroundColumns};
        background-size: ${backgroundWidth} 100%;
        z-index: 10000;
        pointer-events: none;
      }
    `
  } else {
    return ''
  }
}
