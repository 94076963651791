import {Await, useFetcher, useFetchers, useMatches} from '@remix-run/react'
import {Money} from '@shopify/hydrogen-react'

import clsx from 'clsx'
import {Suspense, useEffect} from 'react'
import {CartAction} from '~/utils/types'

import Button from '../shared/button'
import Icon from '../shared/icon'
import IconsPayments from '../shared/icons-payment'
import RichText from '../shared/rich-text'
import {Spinner} from '../shared/spinner'

export function Cart({showCart, setShowCart, data}) {
  const [root] = useMatches()

  const hello = () => {
    return <h1>ghello</h1>
  }
  return (
    // <Suspense fallback={hello}>
    //   <Await resolve={root.data?.cart}>
    //     {(cart) => (
    <CartDrawer cart={root.data.cart} showCart={showCart} setShowCart={setShowCart} data={data} />
    //     )}
    //   </Await>
    // </Suspense>
  )
}

function CartDrawer({cart, showCart, setShowCart, data}) {
  const addToCartFetchers = useCartFetchers('ADD_TO_CART')

  const removeFromCartFetchers = useCartFetchers('REMOVE_FROM_CART')

  useEffect(() => {
    if (showCart || !addToCartFetchers.length) return

    if (addToCartFetchers[0].state === 'submitting') {
      setShowCart(true)
    }
  }, [addToCartFetchers])

  //Optimistic ui ATC
  const cartItems = cart?.preppedItems || []
  let allCartItems = [...cartItems]

  //If there is fetcher data for add tor cart
  if (addToCartFetchers && addToCartFetchers.length >= 1) {
    // Get fetcher data from field on ATC button
    // @ts-ignore-next-line
    const optimisticData = JSON.parse(addToCartFetchers[0].submission.formData.get('optimisticUi'))

    // Check if there is already product in cart with same id
    if (allCartItems.some((obj) => obj.id === optimisticData.id)) {
      const objIndex = allCartItems.findIndex((obj) => obj.id === optimisticData.id)
      allCartItems[objIndex].quantity = null
      // Otheriwse add the 'optimistc data to the cart
    } else {
      allCartItems.push(optimisticData)
    }
  }

  const cartHasItems = allCartItems.length >= 1
  return (
    <section
      className={clsx(
        'bg-skyblue md:w-c-12 fixed top-0 bottom-0 z-40 flex w-full transform-gpu flex-col justify-between duration-300 md:right-0',
        showCart ? 'translate-x-0' : 'translate-x-full'
      )}
    >
      <div className="px-c-1 md:px-c-0.5 flex flex-wrap items-center justify-between">
        <div className="pl-c-1 tracking-pn-3 md:pl-c-0.5 text-[3rem] md:text-[4rem]">
          {data?.bagTitle} ({allCartItems ? allCartItems.length : 0})
        </div>
        <button
          className="p-c-1 my-c-1 ml-c-1 md:ml-c-0.5 md:p-c-0.5 md:my-c-0.5 w-fit"
          onClick={() => setShowCart(false)}
        >
          <Icon type="cross" className="w-[1.3rem] md:w-[1.8rem]" />
        </button>
        <div className="mx-c-1 md:mx-c-0.5 h-[0.1rem] w-full bg-black md:h-[0.2rem]" />
      </div>

      {!cartHasItems && (
        <div className="flex h-full flex-col  items-center justify-between text-center">
          <div />
          <RichText
            data={data.emptyBag}
            className="tracking-pn-3 text-blue text-[5rem] leading-[70%] md:text-[12rem]"
          />
          <Button
            data={{text: 'go shopping', type: 'primary'}}
            className="md:mx-c-1 mx-c-2 !text-sand !w-c-22 md:!w-c-10 mb-[1.6rem] !bg-black md:mb-[4rem]"
          />
        </div>
      )}

      {cartHasItems && (
        <div className="px-c-2 md:px-c-1 flex h-full flex-col justify-between">
          <ul>
            {allCartItems.map(({image, lineId, id, cost, title, quantity}) => {
              return (
                <LineItem
                  lineId={lineId}
                  image={image}
                  cost={cost}
                  key={lineId || id}
                  title={title}
                  quantity={quantity}
                />
              )
            })}
          </ul>
          <div>
            <div>
              <div className="border-t-[.1rem] text-base">
                <div className="flex justify-between pt-[.8rem]">
                  <div className="uppercase">{data?.subtotal}</div>
                  {cart?.cost?.subtotalAmount && <Money data={cart.cost.subtotalAmount} />}
                </div>
                <div className="flex justify-between border-b-[.1rem] pb-[.8rem]">
                  <div className="uppercase">{data?.shipping}</div>
                  <div>{data.shippingCalculated}</div>
                </div>
                <div className="flex justify-between py-[1.6rem] ">
                  <div className="uppercase">{data?.total}</div>
                  {cart?.cost?.subtotalAmount && <Money data={cart?.cost?.totalAmount} />}
                </div>
              </div>
              {/* TODO USPS */}
              {/* <div>{data?.}</div> */}
              <Button
                data={{
                  type: 'primary',
                  text: data?.goToCheckout && data.goToCheckout,
                  externalLink: true,
                }}
                className="flex !w-full !bg-black text-white md:hover:text-white"
                href={cart?.checkoutUrl}
              />

              <IconsPayments
                iconClass="h-[1.6rem] mx-[.8rem] fill-blue my-[1.2rem] md:mx-[1rem] md:h-[2rem] md:my-[2.4rem]"
                wrapperClass="flex justify-center"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

function LineItem({cost, lineId, title, image, quantity}) {
  return (
    <li className="flex justify-between border-b-[.1rem] py-[1rem] md:py-[1.6rem]">
      <div className="flex">
        <img
          src={image}
          height="9.4rem"
          width="9rem"
          className="w-c-4 h-[9.4rem] bg-white object-contain p-[1.2rem] md:w-[9rem]"
          alt="product"
        />
        <div className="pl-c-1 flex flex-col justify-between md:pl-[3.2rem]">
          <div className="tracking-pn-3 text-[1.8rem]">{title}</div>
          <CartLineQuantityAdjust lineId={lineId} quantity={quantity} />
        </div>
      </div>
      <div className="flex flex-col items-end justify-between">
        {cost && <Money data={cost.totalAmount} className="text-right text-base" />}
        <ItemRemoveButton lineIds={[lineId]} />
      </div>
    </li>
  )
}

function CartLineQuantityAdjust({lineId, quantity}) {
  const prevQuantity = Number(Math.max(0, quantity - 1).toFixed(0))
  const nextQuantity = Number((quantity + 1).toFixed(0))
  const fetcher = useFetcher()
  const isLoading = fetcher.state != 'idle' || !quantity

  return (
    <>
      <label htmlFor={`quantity-${lineId}`} className="sr-only">
        Quantity, {quantity}
      </label>
      <div className="flex items-center justify-between rounded-full border-[.1rem] text-[1.2rem]">
        <UpdateCartButton lines={[{id: lineId, quantity: prevQuantity}]} fetcher={fetcher}>
          <button
            name="decrease-quantity"
            aria-label="Decrease quantity"
            className="h-[3.4rem] w-[2.5rem]"
            value={prevQuantity}
            disabled={isLoading || quantity <= 1}
          >
            <span>&#8722;</span>
          </button>
        </UpdateCartButton>

        <div
          className="flex min-w-[4rem] items-center justify-center text-center md:px-[1rem]"
          data-test="item-quantity"
        >
          {!isLoading && quantity}
          <Spinner className={clsx('fill-blue !w-[1.5rem] hidden', isLoading && '!block')} />
        </div>

        <UpdateCartButton lines={[{id: lineId, quantity: nextQuantity}]} fetcher={fetcher}>
          <button
            disabled={isLoading}
            className="h-[3.4rem] w-[2.5rem]"
            name="increase-quantity"
            value={nextQuantity}
            aria-label="Increase quantity"
          >
            <span>&#43;</span>
          </button>
        </UpdateCartButton>
      </div>
    </>
  )
}

function UpdateCartButton({
  children,
  lines,
  fetcher,
}: {
  children: React.ReactNode
  lines: CartLineUpdateEvent[]
}) {
  return (
    <fetcher.Form action="/resources/cart" method="post">
      <input type="hidden" name="cartAction" value={CartAction.UPDATE_CART} />
      <input type="hidden" name="lines" value={JSON.stringify(lines)} />
      {children}
    </fetcher.Form>
  )
}

function ItemRemoveButton({lineIds}: {lineIds: CartLine['id'][]}) {
  const fetcher = useFetcher()
  const isLoading = fetcher.state != 'idle'
  return (
    <fetcher.Form action="/resources/cart" method="post">
      <input type="hidden" name="cartAction" value={CartAction.REMOVE_FROM_CART} />
      <input type="hidden" name="linesIds" value={JSON.stringify(lineIds)} />
      <button className="flex pl-[1.6rem] pt-[1.6rem]" type="submit" disabled={isLoading}>
        <span className="tracking-pn-3 text-base text-[1.2rem] uppercase underline">
          {isLoading ? 'removing' : 'remove'}
        </span>
      </button>
    </fetcher.Form>
  )
}

function useCartFetchers(actionName: string) {
  const fetchers = useFetchers()
  const cartFetchers = []

  for (const fetcher of fetchers) {
    const formData = fetcher.submission?.formData
    if (formData && formData.get('cartAction') === actionName) {
      cartFetchers.push(fetcher)
    }
  }
  return cartFetchers
}
