import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useOutlet,
} from '@remix-run/react'

import {useEffect} from 'react'
import {LazyMotion} from 'framer-motion'

import {generateGrid} from '~/styles/grid'
import cms from './cms.server'
import {Layout} from './components/layout'

import styles from './styles/app.css'
import {getLocaleFromRequest} from './utils/locale'
import {
  defer,
  HeadersFunction,
  json,
  LinksFunction,
  LoaderFunction,
  MetaFunction,
} from '@shopify/remix-oxygen'

import {getCart} from './models/shopify.server'
import {useShopifyAnalytics} from './hooks/use-shopify-analytics'
import {ShopifySalesChannel} from '@shopify/hydrogen'

export const links: LinksFunction = () => {
  return [
    {
      rel: 'icon',
      type: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicon/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/favicon/site.webmanifest',
    },
    {
      rel: 'preconnect',
      href: 'https://cdn.shopify.com',
    },
    {
      rel: 'preconnect',
      href: 'https://shop.app',
    },
    {rel: 'stylesheet', href: styles},
    //TODO fix: A preload for 'https://wakey-hydrogen.pages.dev/founders.woff2' is found, but is not used because the request credentials mode does not match. Consider taking a look at crossorigin attribute.
    // {
    //   rel: 'preload',
    //   href: '/itc.woff2',
    //   type: 'font/woff2',
    //   as: 'font',
    // },
    // {
    //   rel: 'preload',
    //   href: '/founders.woff2',
    //   type: 'font/woff2',
    //   as: 'font',
    // },
    // preconnect cdn Sanity?
    // {
    //   rel: 'preconnect',
    //   href: 'https://cdn.shopify.com',
    // },
  ]
}

const loadFeatures = () => import('./motion.ts').then((res) => res.default)

export const headers: HeadersFunction = () => {
  // See this https://www.selbekk.io/blog/moving-from-next-to-remix
  // And this https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control
  return {
    'Cache-Control': 's-maxage=86400, stale-while-revalidate=604800',
  }
}

export const loader: LoaderFunction = async ({request, context}) => {
  const selectedLocale = getLocaleFromRequest(request)

  let [settings, cartId, shop] = await Promise.all([
    cms.getSettings({locale: selectedLocale.language}),
    context.session.get('cartId'),
    context.storefront.query(`query {
      shop {
        id
      }
    }`),
  ])

  const cart = await getCart(context, cartId)

  return json({
    settings,
    selectedLocale,
    cart,
    analytics: {
      shopifySalesChannel: ShopifySalesChannel.hydrogen,
      shopId: shop.shop.id,
    },
  })
}

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  viewport: 'width=device-width,initial-scale=1',
  'theme-color': '#FAD103',
  'og:site_name': 'wakeycare',
})

export default function App() {
  const {settings, selectedLocale} = useLoaderData()
  const location = useLocation()

  useShopifyAnalytics(true, selectedLocale)

  // Chrome CSS bug
  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  return (
    <html lang={selectedLocale.language.toLowerCase()}>
      <head>
        <Meta />
        <Links />
        <style>{generateGrid()}</style>
      </head>
      <body className="loading">
        <LazyMotion features={loadFeatures} strict>
          <Layout data={settings}>
            <Outlet />
          </Layout>
        </LazyMotion>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  )
}
