import {Link} from '../shared/link'
import IconsPayments from '../shared/icons-payment'

import IconsSocial from '../shared/icons-social'

export function Footer({data}: any) {
  return (
    <footer className="bg-ocher text-sand px-c-2 py-c-3 pb-c-6 md:pt-c-1.5 relative md:pb-0">
      <div className="wrapper">
        <div className="md:grid md:grid-cols-2">
          <nav className="tracking-pn-3 pb-c-4 text-[3rem] leading-[150%] md:grid md:grid-cols-2 md:pb-[12rem] md:text-[4rem]">
            <ul>
              {data.menuOne.map(({text, link}: any) => (
                <li key={text}>
                  <Link to={link}>{text}</Link>
                </li>
              ))}
            </ul>
            <ul className="md:ml-c-1">
              {data.menuTwo.map(({text, link}: any) => (
                <li key={text}>
                  <Link to={link}>{text}</Link>
                </li>
              ))}
            </ul>
          </nav>
          <div>
            <div className="tracking-pn-3 pb-c-2 md:pl-c-2 hidden  text-[3rem] leading-[150%] md:text-[4rem]">
              {data.contactTitle}
            </div>
          </div>
        </div>
        <div className="border-sand border-b-[0.05rem] md:flex md:items-center md:justify-between">
          <IconsSocial
            iconClass="h-[2.1rem] fill-sand"
            linkClass="py-c-1 px-c-1 md:py-[1.6rem] md:px-[1.6rem]"
            wrapperClass="ml-[-1.6rem] py-c-0.5 pb-c-1 md:py-[2.9rem]"
          />
          <IconsPayments
            iconClass="h-[1.8rem] md:ml-[1.6rem] fill-sand"
            wrapperClass="hidden md:flex ml-[-1.6rem] py-c-0.5 pb-c-1 md:py-[2.9rem]"
          />
        </div>
        <div className="md:flex md:flex-row-reverse md:justify-between">
          {/* TODO: Swqapped for real lang adjustment */}
          <div className="pt-c-1.5 text-base md:py-[3rem]">
            <span className="pr-[1.6rem] underline">Dutch</span>
            <span className="pr-[1.6rem] underline md:pr-0">Nederand</span>
          </div>
          <IconsPayments
            iconClass="h-[1.8rem] mr-[1.6rem] mt-c-1.5 mb-c-1.5 fill-sand"
            wrapperClass="md:hidden"
          />
          <ul className="flex flex-wrap text-base md:flex-row-reverse md:items-center">
            {data.menuTerms.map(({text, link}: any) => (
              <li key={text} className="mr-[1.2rem] underline">
                <Link to={link}>{text}</Link>
              </li>
            ))}
            <li className="w-full md:mr-[1.2rem] md:w-fit">© 2023 Wakey All rights reserved. </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
