import {Link} from '../shared/link'
import {clsx} from 'clsx'
import Icon from '../shared/icon'

export function Nav({data, showNav, onNavButtonClick}: any) {
  return (
    <nav
      className={clsx(
        'bg-yellow md:w-c-12  ease-wakey fixed top-0 bottom-0 z-40 flex w-full  transform-gpu flex-col  justify-between duration-300',
        showNav ? 'translate-x-0' : '-translate-x-full'
      )}
    >
      <button
        className="p-c-1 my-c-1 ml-c-1 md:ml-c-0.5 md:p-c-0.5 md:my-c-0.5 w-fit"
        onClick={onNavButtonClick}
      >
        <Icon type="cross" className="w-[1.3rem] md:w-[1.8rem]" />
      </button>
      <div className="p-c-2 md:p-c-1">
        <ul className="pb-c-1 md:pb-[1.6rem]">
          {data.mainMenu.map(({text, link}: any) => {
            return (
              <li key={text} className="tracking-pn-3 text-[4rem] leading-[130%]">
                <Link
                  to={link}
                  onClick={onNavButtonClick}
                  className={({isActive}) => (isActive ? 'underline' : undefined)}
                >
                  {text}
                </Link>
              </li>
            )
          })}
        </ul>
        <ul>
          {data.secondaryMenu.map(({text, link}: any) => {
            return (
              <li key={text} className="tracking-pn-3 text-[2.4rem] leading-[160%]">
                <Link to={link} onClick={onNavButtonClick}>
                  {text}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </nav>
  )
}
