import React, {useEffect, useRef} from 'react'
import {useStore} from '~/utils/store'

import Icon from '../shared/icon'
import {Link} from '../shared/link'

export function Header(props) {
  const ref = useRef()
  const {onNavButtonClick, onCartButtonClick} = props
  const buttonClass =
    'bg-white h-[3.4rem] w-[4.7rem] flex justify-center items-center rounded-[2.5rem]'

  const headerColor = useStore(({headerColor}) => headerColor)

  const setHeaderRootMargin = useStore(({setHeaderRootMargin}) => setHeaderRootMargin)

  useEffect(() => {
    if (ref && ref.current) {
      let headerMargin = 0
      const rootMargin = `-${headerMargin}px 0px -${
        window.innerHeight - (ref.current.clientHeight - headerMargin / 2)
      }px 0px`

      setHeaderRootMargin(rootMargin)
    }
  }, [])

  return (
    <header className="px-c-1 pt-c-1 md:pt-c-0.5 fixed left-0 right-0 top-0 z-40" ref={ref}>
      <div className="wrapper flex flex-row items-center justify-between ">
        <button className={buttonClass} onClick={onNavButtonClick}>
          <svg
            className="w-[1.9rem]"
            viewBox="0 0 19 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="1.25" x2="19" y2="1.25" stroke="black" strokeWidth="1.5" />
            <line y1="5.25" x2="19" y2="5.25" stroke="black" strokeWidth="1.5" />
            <line y1="9.25" x2="19" y2="9.25" stroke="black" strokeWidth="1.5" />
          </svg>
        </button>
        <Link to="/">
          <Icon type="w" className="w-[4.5rem]" style={{fill: headerColor}} />
          <div className="sr-only">Home logo</div>
        </Link>
        <button className={buttonClass} onClick={onCartButtonClick}>
          <svg
            className="w-[1.4rem]"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="1" y1="15.7278" x2="14.4472" y2="15.7278" stroke="black" />
            <line x1="1" y1="2.9209" x2="14.4472" y2="2.9209" stroke="black" />
            <line x1="10.7461" y1="4.70166" x2="10.7461" y2="1.49995" stroke="black" />
            <path d="M11.2461 1L3.84375 1" stroke="black" />
            <line x1="4.34375" y1="4.70166" x2="4.34375" y2="1.49995" stroke="black" />
            <line x1="13.9473" y1="16.2278" x2="13.9473" y2="2.78061" stroke="black" />
            <path d="M1 16.2278L1 2.42566" stroke="black" />
          </svg>
        </button>
      </div>
    </header>
  )
}

export function useHeaderRootMargin({
  ref,
  color,
}: {
  ref: React.MutableRefObject<HTMLElement | null>
  color: string
}) {}
